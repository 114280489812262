import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
  {
    path: '/:app',
    name: 'home',
    component: () => import('@/webapp/index.vue')
  },
  {
    path: '/:app/order/:orderId',
    name: 'order',
    component: () => import('@/webapp/order.vue')
  },
  {
    path: '/:app/goods/:id',
    name: 'goods',
    component: () => import('@/webapp/goods.vue')
  }
//   {
//     path: '*',
//     name: '404',
//     component: () => import('@/webapp/error.vue')
//   }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
